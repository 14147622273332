<template>
	<div>
		<!-- 内容 -->
		<div class="orderdetailsbox">
			<div class="orderback">
				<a href="javascript:void(0);" @click="headback()"><i class="el-icon-arrow-left"></i> 返回</a>
				<span>/</span>
				<h5>售后详情</h5>
			</div>
			<div class="orderdetailsup servicedetailsup">
				<ul class="clearfloat">
					<li class="active">
						<h5><span>1</span></h5>
						<p>售后申请</p>
						<strong>{{servuceconter.created_at}}</strong>
					</li>
					<template v-if="servuceconter.status == 3">
						<li class="active">
							<h5><span>2</span></h5>
							<p>处理售后申请</p>
							<strong>{{servuceconter.response_time}}</strong>
						</li>
						<li class="active">
							<h5><span>3</span></h5>
							<p>售后完成</p>
							<strong></strong>
						</li>
					</template>
					<template v-else>
						<li :class="servuceconter.status == 1 ? 'active' : ''">
							<h5><span>2</span></h5>
							<p>处理售后申请</p>
							<strong>{{servuceconter.response_time}}</strong>
						</li>
						<li :class="servuceconter.status == 4 ? 'active' : ''">
							<h5><span>3</span></h5>
							<p>售后完成</p>
							<strong></strong>
						</li>
					</template>
				</ul>
			</div>
			<div class="orderdetailscon">
				<ul class="clearfloat d-flex">
					<li>
						<div class="orderdetailstitle clearfloat">
							<h5>订单信息</h5>
						</div>
						<div class="orderdetailsbut">
							<p class="clearfloat">
								<span>订单编号</span>
								<strong>{{servuceconter.order_refund_no}}</strong>
							</p>
							<p class="clearfloat">
								<span>订单类型</span>
								<strong>{{common_info['goods.vr_type.map']?.[servuceconter.order?.vr_type]}}</strong>
							</p>
							<p class="clearfloat">
								<span>订单来源</span>
								<!-- <strong>{{common_info['order.type.map'][servuceconter.order?.order_source]}}</strong> -->
								<strong>
									<template v-if="servuceconter.order.order_source_name">{{
									servuceconter.order.order_source_name
									}}</template>
									<template v-if="servuceconter.order.order_source2_name"
									>-{{ servuceconter.order.order_source2_name }}</template
									>
								</strong>
								
							</p>
							<p class="clearfloat">
								<span>支付方式</span>
								<strong>{{common_info['order.payment.type.map'][servuceconter?.order?.pay_type]}}</strong>
							</p>
						</div>
					</li>
					<li>
						<div class="orderdetailstitle clearfloat">
							<h5>售后信息</h5>
						</div>
						<div class="orderdetailsbut">
							<p class="clearfloat">
								<span>售后状态</span>
								<template v-if="servuceconter.order.application!='platform_fl'">
									<strong v-if="servuceconter.status == 0">待商家处理</strong>
									<strong v-if="servuceconter.status == 1">同意并已退款</strong>
									<strong v-if="servuceconter.status == 2">已同意换货</strong>
									<strong v-if="servuceconter.status == 3">已拒绝退换货</strong>
									<strong v-if="servuceconter.status == 4">已完成</strong>
								</template>
								<template v-else>
									<strong v-if="servuceconter.status == 0">待处理</strong>
									<strong v-if="servuceconter.status == 1">同意售后</strong>
									<strong v-if="servuceconter.status == 3">已拒绝售后</strong>
								</template>
							</p>
							<p class="clearfloat">
								<span>售后类型</span>
								<template v-if="servuceconter.order.application!='platform_fl'">
									<strong v-if="servuceconter.type == 1">退货退款</strong>
									<strong v-if="servuceconter.type == 2">换货</strong>
									<strong v-if="servuceconter.type == 3">仅退款</strong>
								</template>
								<template v-else>
									<strong v-if="servuceconter.type == 4">仅售后</strong>
								</template>
							</p>
							<p class="clearfloat" v-if="servuceconter.type == 1">
								<span>申请售后金额</span>
								<strong>{{servuceconter.refund_price}}</strong>
							</p>
							<p class="clearfloat">
								<span>售后原因</span>
								<strong>{{servuceconter.reason}}</strong>
							</p>
							<p class="clearfloat">
								<span>售后说明</span>
								<strong>{{servuceconter.desc}}</strong>
							</p>
							<p class="clearfloat">
								<span>售后编号</span>
								<strong>{{servuceconter.order_refund_no}}</strong>
							</p>
						</div>
					</li>
					<li>
						<div class="orderdetailstitle clearfloat">
							<h5>其他信息</h5>
						</div>
						<div class="orderdetailsbut">
							<p class="clearfloat" v-if="servuceconter.type == 1">
								<span>退款金额</span>
								<strong>{{servuceconter.refund_price}}</strong>
							</p>
						</div>
					</li>
				</ul>
			</div>
			<div class="orderdetailsbuton">
				<div class="orderdetailstitle clearfloat">
					<h5>商品信息</h5>
				</div>
				<div class="orderdetailtable">
					<div class="servicetitle clearfloat">
						<p>商品</p>
						<p>单价</p>
						<p>购买数量</p>
						<!-- <p>优惠</p> -->
						<p>小计</p>
						<p>状态</p>
						<p>售后金额</p>
					</div>
					<div class="orderdetailtablecon">
						<div class="orderdetailtablelist d-flex align-items-center" v-for="(item,index) in servuceconter.goods" :key="index">
							<div class="orderdetail-itemed">
								<div class="order_itemlist d-flex align-items-center">
									<div class="order_itemimg">
										<img :src="item.pic" alt="">
									</div>
									<div class="order_itemtext">
										<a href="javascript:void(0);">{{item.goods_name}}</a>
										<div class="orderguige clearfloat">
											<p v-for="(ite,ind) in item.attr" :key="ind"><span>{{ite.specs_key}}：</span>{{ite.specs_value}}</p>
										</div>
										<div class="orderSupplier" v-if="servuceconter.order.supplier">供应商名称：{{servuceconter.order.supplier.name}}</div>
									</div>
								</div>
							</div>
							<div class="orderdetail-itemed">
								<div class="orderdetail-itemtext">
									<p v-if="servuceconter.order.application!='platform_fl'">￥{{item.price}}</p>
									<p v-else>{{item.price}}积分</p>
								</div>
							</div>
							<div class="orderdetail-itemed">
								<div class="orderdetail-itemtext">
									<p>{{item.num}}</p>
								</div>
							</div>
							<!-- <div class="orderdetail-itemed">
								<div class="orderdetail-itemtext">
									<p>0</p>
								</div>
							</div> -->
							<div class="orderdetail-itemed">
								<div class="orderdetail-itemtext">
									<p v-if="servuceconter.order.application!='platform_fl'">￥{{item.total_price}}</p>
									<p v-else>{{item.total_price}}积分</p>
								</div>
							</div>
							<div class="orderdetail-itemed">
								<div class="orderdetail-itemtext">
									<p>{{servuceconter.order_send_status == 1 ? "已发货" : "未发货"}}</p>
								</div>
							</div>
							<div class="orderdetail-itemed">
								<div class="orderdetail-itemtext">
									<p>{{item.total_price}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="orderconsult">
				<div class="orderdetailstitle clearfloat">
					<h5>协商信息</h5>
				</div>
				<div class="orderconsultcon">
					<ul>
						<li v-if="!servuceconter.status == 0">
							<div class="orderconsultup">
								<p><span>商家</span> <strong>{{servuceconter.response_time}}</strong></p>
								<!-- <p>{{servuceconter.refuse_desc}}</p> -->
								<p v-if="servuceconter.refuse_desc">
									<template v-if="servuceconter.is_agree==1">同意原因：</template>
									<template v-else-if="servuceconter.is_agree==2">拒绝原因：</template>
									{{servuceconter.refuse_desc}}</p>
							</div>
							<div class="orderconsultbut">
								<p v-if="servuceconter.type == 1"><span>退款金额：</span> <strong>{{servuceconter.refund_price}}</strong></p>
							</div>
						</li>
						<li>
							<div class="orderconsultup">
								<p><span>买家</span> <strong>{{servuceconter.created_at}}</strong></p>
								<p>发起了售后申请,等待商家处理</p>
							</div>
							<div class="orderconsultbut">
								<p>
									<span>处理方式：</span> 
									<strong v-if="servuceconter.type == 1">退货退款</strong>
									<strong v-if="servuceconter.type == 2">换货</strong>
									<strong v-if="servuceconter.type == 3">仅退款</strong>
									<strong v-if="servuceconter.type == 4">售后</strong>
								</p>
								<p v-if="servuceconter.type == 1"><span>退款金额：</span> <strong>{{servuceconter.refund_price}}</strong></p>
								<p><span>售后原因：</span> <strong>{{servuceconter.reason}}</strong></p>
								<p><span>售后说明：</span> <strong>{{servuceconter.desc}}</strong></p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				id: this.$route.query.id,
				servuceconter: '',//详情信息
			};
		},
		beforeCreate(){
			this.$store.commit("commonInfo","");
		},
		created() {
			let data = {
				keys: ["goods.vr_type.map","order.type.map","order.payment.type.map","order.source.map"]
			}
			this.common.getCommonInfo(this, data);
		},
		computed: {
			common_info: {
				get() {
					let stateuser = {
						'goods.vr_type.map': [],//订单类型
						'order.type.map': [],//订单来源
						'order.payment.type.map': {},//支付方式
						"order.source.map":[]
					}
					let val = this.$store.state.common_info || stateuser;
					return val
				},
				set() {}
			}
		},
		mounted() {
			this.servicedetailapi(this.id);
		},
		methods: {
			servicedetailapi(id){
				// console.log(data)
				this.$get(this.$apis.servicedetails + id).then(res => {
					if (res.code == 200) {
						let order_source_list = this.common_info["order.source.map"];
						for (var i in order_source_list) {
							if (res.data.order.application && res.data.order.application == i) {
								res.data.order.application_name = order_source_list[i].label;
								if (res.data.order.order_source && order_source_list[i].children) {
									for (var j in order_source_list[i].children) {
										if (res.data.order.order_source == j) {
										res.data.order.order_source_name =
											order_source_list[i].children[j].label;
											if (
												res.data.order.order_source2 &&
												order_source_list[i].children
											) {
												for (var a in order_source_list[i].children[j]
												.children) {
													if (res.data.order.order_source2 == a) {
														res.data.order.order_source2_name =
														order_source_list[i].children[j].children[
															a
														].label;
													}
												}
											}
										}
									}
								}
							}
						}
						this.servuceconter = res.data
						console.log(this.servuceconter)
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			},
			headback() {
				window.history.go(-1)
			},
		}
	};
</script>
<style scoped>
	@import url("css/order.css");
	
.orderSupplier{
	margin-top: 5px;
}
</style>